.app-home-icon{
  position: absolute;
  top: 12px;
  left: 12px;
}
.app-logo-text {
  display: block;
  text-align: center;
  color: #181a1f;
  font-size: 10px;
}
.app-logo-text-link{
  color: #181a1f;
}
.app-home-welcome-text{
  font-size: 24px;
  font-weight: 700;
  color: #181a1f;
  text-align: center;
}
.app-home-username{
  color: rgb(142, 150, 255);
}
.home-button-container {
  padding-top: 30px;
}
.home-button-container-hint{
  font-size: 14px;
}
.home-button {
  display: block;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #181a1f;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 60px;
  width: 100%;
  height: 60px;
  font-size: 16px;
  font-weight: bold;
}
.app-top-header-button{
  display: block;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #181a1f;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 60px;
  width: 100%;
  height: 48px;
  font-size: medium;
  font-weight: bold;
}
.logout-button{
  background-color: #dc3545;
}
@media only screen and (max-width: 600px) {
  .app-home-welcome-text{
    /* font-size: 24px; */
  }
}
@media only screen and (max-width: 991px) {
  .mobile-only{
    display: block;
  }
  .desktop-only{
    display: none;
  }
}
@media only screen and (min-width: 992px) {
  .mobile-only{
    display: none;
  }
  .desktop-only{
    display: auto;
  }
}
.MuiMultiSectionDigitalClock-root>.Mui-disabled{
  display: none;
}
