.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: start ;
  float: center;
  background-color: #ffFFff;
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.login-page {
  display: grid;
  justify-content: center;
  align-items: center;
  float: center;
  position: relative;
  top: 44px;
}

.login-logo {
  /* display: grid; */
  justify-content: center;
  align-items: center;
  position: flex;
  left: 0px;
  top: 44px;
  margin-top: 36px;
  height: 120px;
}
.login-container-text{
  font-size: 24px;
  font-weight: 800;
  color: #181a1f;
  text-align: center;
}
.card-container-username{
  color: rgb(142, 150, 255);
}
.login-page-form {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #181a1f;
  font-size: medium;
  font-weight: bold;
  position: relative;
  width: 100%;
  min-height: 320px;
}
.login-page-form-buttom {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: medium;
  font-weight: bold;
  position: relative;
  width: 100%;
  height: 120px;
}
.login-page-form-label {
  width: 100%;
}
.login-page-form-block {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 12px;
  color: white;
}
.login-page-form-block>div{
  background: white;
  border: 2px solid;
  border-color: black;
  border-radius: 60px;
  padding: 0 8px;
  width: 100%;
  height: 48px;
}
.login-setting {
  display: grid;
  justify-content: center;
  align-items: center;
  float: center;
  margin-top: 10px;
}
.login-bottom {
  justify-content: center;
  align-items: center;
}

.login-bottom-container {
  padding-top: 30px;
}
.login-bottom-container-text {
  display: flex;
  padding: 8px;
}
.login-bottom-container-text-left{
  text-align: left;
  cursor: pointer;
  width: 40%;
}
.login-bottom-container-text-right{
  text-align: right;
  cursor: pointer;
  width: 60%;
}
@media (max-width: 480px){
  .login-bottom-container-text {
    display: block;
    padding: 8px;
  }
  .login-bottom-container-text-left{
    text-align: center;
    cursor: pointer;
    width: 100%;
  }
  .login-bottom-container-text-right{
    text-align: center;
    cursor: pointer;
    width: 100%;
  }
}
.visitor-form{
  padding-bottom: 40px;
}
.login-bottom-container-submit {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  float: center;
  border: none;
  background-color: rgb(142, 150, 255);
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 60px;
  width: 100%;
  height: 48px;
  font-size: medium;
  font-weight: bold;
}

.login-bottom-container-register {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  float: center;
  border: none;
  background-color: #000000;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 60px;
  width: 100%;
  height: 48px;
  font-size: medium;
  font-weight: bold;
}

.login-bottom-container-back {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  float: center;
  border: none;
  background-color: #3a3a3a;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 60px;
  width: 100%;
  height: 48px;
  font-size: medium;
  font-weight: bold;
}

.login-bottom-register-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  float: center;
  padding-top: 15px;
}

.login-bottom-register-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  float: center;
  background-color: inherit;
  border: 0px;
  color: #B47D64;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: while !important;
  opacity: 1 !important; /* Firefox */
}
.login-page-middle-break {
  padding: 60px;
  display: flex;
  justify-content: center;
}
.vertical-line{
  border-left: 4px solid #181a1f;
  height: 280px;
}
.horizontal-line {
  border-top: 4px solid #181a1f;
  width: 100%;
}
@media (max-width: 992px){
  .horizontal-line {
    display: block;
  }
  .vertical-line {
    display: none;
  }
}
@media (min-width: 992px){
  .horizontal-line {
    display: none;
  }
  .vertical-line {
    display: block;
  }
}
